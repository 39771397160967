import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home/";
import "./index.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Footer from "./components/Footer";
import WorkFlow from "./components/pages/WorkFlow";
import PortFolio from "./components/pages/PortFolio";
import Contact from "./components/pages/Contact";

function App() {
    return (
        <div>
            <Router>
                <Navbar />

                <Switch>
                    <Route path={'/home'}>
                        <Home/>
                    </Route>
                    <Route path={'/workFlow'}>
                        <WorkFlow />
                    </Route>
                    <Route path={'/portfolio'}>
                        <PortFolio />
                    </Route>
                    <Route path={'/contact'}>
                        <Contact />
                    </Route>
                    <Route path={"*"}><Redirect to={'/home'} /></Route>
                </Switch>

                <Footer />
            </Router>

        </div>
    );
}

export default App;
