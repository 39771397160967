import React, {useEffect, useRef, useState} from "react";
import CaretRight from "../../CaretRight";
import CaretUp from "../../CarretUp";

function Box({title, description, id}) {
    const [open, setOpen] = useState(false);
    const collapse = useRef(null);

    useEffect(() => {
        const collapseElement = collapse.current;

        collapseElement.addEventListener('show.bs.collapse', onOpen);
        collapseElement.addEventListener('hide.bs.collapse', onClose);

        function onClose(evt) {
            setOpen(false);
        }
        function onOpen(evt) {
            setOpen(true);
        }

        return () => {
            collapseElement.removeEventListener('show.bs.collapse', onOpen);
            collapseElement.removeEventListener('hide.bs.collapse', onClose);
        };
    }, []);

    return (
        <div className={"bg-white p-3 mx-5 rounded border shadow-sm"}>
            <div className={"d-flex  flex-row justify-content-between"} style={{cursor: "pointer"}} onClick={(evt) => {
                const collapseInstance = new window.bootstrap.Collapse(collapse.current);
                collapseInstance.toggle();
            }}>
                <h4 className={"m-0"} style={{width: '100%'}}>{title}</h4>
                <div className={""}>
                    {open ? <CaretUp />: <CaretRight />}
                </div>
            </div>
            <div className={"collapse mt-3"} id={"collapse" + id} ref={collapse}>
                <p dangerouslySetInnerHTML={{__html: description}}></p>
            </div>
        </div>
    );
}

export default Box;