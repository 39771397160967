import React from "react";
import picture from "../../../laurentPic.png";

function Home() {
    document.title = "LDIT - Home";

    return (
        <div className={"bg-light"}>
            <div className={"bg-white "} style={{clipPath: "polygon(0% 0%, 0% 70%, 50% 100%, 100% 70%, 100% 0%)"}}>
                <div className={"container"} style={{paddingBottom: '20vh', paddingTop: '10vh'}}>
                    <div className="row align-items-center ">
                        <div className="col-12 col-md-6 text-md-start text-center">
                            <h1><span style={{color:  'rgba(230,25,51,1)'}}>&lt;</span>Hey, ik ben Laurent <span style={{color:  'rgba(230,25,51,1)'}}>/&gt;</span></h1>
                            <h1 style={{color: 'rgba(230,25,51,1)'}}>Fullstack Ontwikkelaar</h1>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="text-md-end text-center">
                                <img src={picture} alt="" height={200}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-white text-center mt-5 " style={{backgroundColor: 'rgba(255,42,59,1)'}}>
                <div className="container py-5">
                    <h2 className={"mb-3"}>Over mijzelf</h2>
                    <p>Al sinds kinds af aan ben ik geïnteresseerd in computers en technologie.
                        Ik heb de juiste kansen op de juiste momenten gekregen en genomen om me hierin te verdiepen.</p>
                    <p>Software ontwikkeling is mijn passie. Ik ben altijd zeer gedreven om nieuwe dingen te leren, dat is
                        en zal altijd het leukste zijn van mijn stiel, nieuwe technologieën ontdekken.</p>
                    <p>Ik ben afkomstig uit Gent en mijn voertaal is Nederlands. Ook spreek ik vloeiend Engels.</p>
                </div>
            </div>

            <div className="container mt-5">
                <h2 className={"text-center mb-5"}>Wat ik doe <span style={{color: 'rgba(89,100,110,1)'}}>en niet doe</span></h2>

                <div className="d-flex justify-content-center">
                    <div className="shadow-sm rounded d-inline-block mx-auto bg-white p-md-5 p-4 border">
                        <ul style={{listStyleType: 'none'}} className={"p-0"}>
                            <li style={{fontSize: '24px', fontWeight: 'bold'}} className={"mb-3"}>
                                <div className="d-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="green"
                                             className="bi bi-check-square me-3" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path
                                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Front/back end ontwikkeling
                                    </div>
                                </div>
                            </li>
                            <li style={{fontSize: '24px', fontWeight: 'bold'}} className={"mb-3"}>
                                <div className={"d-flex"}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="green"
                                             className="bi bi-check-square me-3" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path
                                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Web/Mobiele/Native applicaties
                                    </div>
                                </div>
                            </li>
                            <li style={{fontSize: '24px', fontWeight: 'bold'}} className={"mb-3"}>
                                <div className={"d-flex"}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="green"
                                             className="bi bi-check-square me-3" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path
                                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        POF’s, wireframes
                                    </div>
                                </div>
                            </li>
                            <li style={{fontSize: '24px', fontWeight: 'bold'}} className={"mb-3"}>
                                <div className={"d-flex"}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="green"
                                             className="bi bi-check-square me-3" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path
                                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Scripts, geautomatiseerde scripts
                                    </div>
                                </div>
                            </li>
                            <li style={{fontSize: '24px', fontWeight: 'bold'}} className={"mb-3"}>
                                <div className="d-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="green"
                                             className="bi bi-check-square me-3" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path
                                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Implementeren van bestaande toepassingen
                                    </div>
                                </div>
                            </li>
                            <li style={{fontSize: '24px', fontWeight: 'bold'}}>
                                <div className="d-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="green"
                                             className="bi bi-check-square me-3" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path
                                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Web scraping, bots
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <hr />
                        <ul style={{listStyleType: 'none'}} className={"p-0"}>
                            <li style={{fontSize: '24px', color: 'rgba(89,100,110,1)', fontWeight: 'bold'}}>
                                <div className="d-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg"  width="27" height="27" fill="red"
                                             className="bi bi-x-square me-3" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path
                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Beschrijvende non functionele websteks zoals deze
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Home;