import React, {useState} from "react";

function Contact() {
    document.title = "LDIT - Contact";
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage]= useState('');

    return (
        <div className={"bg-light"}>
            <div className={"bg-white "} style={{clipPath: "polygon(0% 0%, 0% 70%, 50% 100%, 100% 70%, 100% 0%)"}}>
                <div className={"container"} style={{paddingBottom: '20vh', paddingTop: '10vh'}}>
                    <div className="d-flex gap-4 flex-md-nowrap flex-wrap justify-content-center">
                        <div className={"my-4 d-flex justify-content-center"}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="150" viewBox="0 0 24 24">
                                <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none"/>
                                <path fill='rgba(230,25,51,1)' d="M20 0H4v2h16V0zM4 24h16v-2H4v2zM20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 2.75c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25S9.75 10.24 9.75 9 10.76 6.75 12 6.75zM17 17H7v-1.5c0-1.67 3.33-2.5 5-2.5s5 .83 5 2.5V17z"/>
                            </svg>
                        </div>
                        <div className="d-flex justify-content-center flex-column">
                            <div>
                                <h1>Contact</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container bg-light pt-5" >

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="name" placeholder="Laurent Dhont" value={name} onChange={(evt) => {
                        setName(evt.target.value);
                    }}/>
                    <label htmlFor="name">Volledige naam</label>
                </div>
                <div className="form-floating mb-3 ">
                    <input type="text" className="form-control" id="telephoneNumber" placeholder={"+32486643090"} value={phoneNumber} onChange={(evt) => {
                        setPhoneNumber(evt.target.value);
                    }} />
                    <label htmlFor="telephoneNumber">Telefoon nummer</label>
                </div>
                <div className="form-floating mb-3">
                    <textarea className="form-control" placeholder="..." id="message"
                              style={{height: '100px'}} value={message} onChange={(evt) => {
                                  setMessage(evt.target.value);
                    }}/>
                    <label htmlFor="message">Bericht</label>
                </div>
                <a className={"btn rounded px-3 py-2 text-white w-100"} style={{backgroundColor: 'rgba(230,25,51,1)'}} href={"mailto:laurent@ldit.be?body="  + encodeURI( message + "\n\nNaam:" + name + "\nTelefoon nummer:" + phoneNumber)}>Verstuur</a>
            </div>
        </div>
    );
}

export default Contact;