import React from "react";

function Footer() {
    return (
        <div className={"bg-light pt-5"}>
            <div className={"bg-white"} style={{borderBottom: "7px solid rgba(255,42,59,1)", paddingTop: '10vh', clipPath: 'polygon(0% 0%, 100% 40%, 100% 100%, 0% 100%, 0% 0%)'}}>
                <div className="container">
                    <h3 style={{color: 'rgba(255,42,59,1)'}}><b>LDIT BV</b></h3>

                    <div className="my-3 d-flex align-items-center justify-content-between">
                            <div>
                                <p className={"m-0"}>Drieleienstraat 39</p>
                                <p className={"m-0"}>9031 Drongen</p>
                                <p className={"m-0"}>Oost-Vlaanderen</p>
                                <p className={"m-0"}>Vlaanderen</p>
                            </div>
                            <div>
                                <p className={"m-0"}>VAT: <b>BE 0735.515.069</b></p>
                                <p className={"m-0"}>Email: <a><b>laurent@ldit.be</b></a></p>
                                <p className={"m-0"}>Tel: <a><b>+32 486 643 090</b></a></p>
                            </div>



                        <div className="">
                            <a href="https://www.linkedin.com/in/laurent-dhont-1a181b1a6/" target="_blank">
                                <svg id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.499" viewBox="0 0 31.5 31.499">
                                    <path id="Icon_awesome-linkedin-in-2" data-name="Icon awesome-linkedin-in" d="M7.051,31.5H.52V10.47H7.051ZM3.782,7.6A3.8,3.8,0,1,1,7.564,3.783,3.814,3.814,0,0,1,3.782,7.6ZM31.493,31.5H24.976V21.263c0-2.44-.049-5.569-3.4-5.569-3.4,0-3.916,2.651-3.916,5.393V31.5H11.142V10.47h6.263v2.869H17.5a6.862,6.862,0,0,1,6.179-3.4c6.609,0,7.824,4.352,7.824,10.005V31.5Z" transform="translate(0 -0.001)" fill="#e61933"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <p className={"text-center mt-5"}><b>Copyright © 2022 LDIT - All rights reserved.</b></p>
                </div>
            </div>
        </div>
    );
}

export default Footer;