import React from "react";
import Box from "./Box";

const items = [{
    title: "Bespreken van het project (specificaties, advies, beperkingen, deadline, …)",
    description: "Eerst en vooral moet het duidelijk zijn voor beide partijen wat er moet gebeuren. Ik doe echter niet alleen software ontwikkeling maar help de klant ook bij het nadenken en uitwerken van een toepassing.<br />" +
        "<br />" +
        "Indien er nog geen specificaties zijn, kan ik de klant ook helpen om deze op te maken.<br />" +
        "<br />" +
        "Voor grote projecten is het van uitermate belang om een POF (Proof of Concept) te maken alvorens het project volledig uit te werken. Op deze manier kunnen de beperkingen/problemen vroegtijdig gevonden worden zodat ze later niet onverwachts aan de deur komen kloppen. De specificaties kunnen zo ook aangepast worden aangezien er al een conceptualisatie is.<br />" +
        "<br />" +
        "Bepaalde projecten onder tijdsdruk vereisen een deadline. Het is dus ook belangrijk om een haalbare deadline af te spreken. Ik kijk hier dus ook na of ik over de benodigde tijd beschik en het project wil aannemen of niet."
}, {
    title: "Prijs onderhandeling, offerte opstellen",
    description: "Een inschatting van de hoeveelheid werk zal worden gemaakt. Een offerte wordt opgesteld en doorgestuurd.<br />" +
        "Deze offerte kan een stappenplan bevatten, met hierbij aangekoppelde betalingen."
}, {
    title: "Ontwikkeling",
    description: "Een stack zal worden samengesteld. Welke talen, bibliotheken en frameworks wil ik gebruiken? Dit kan verschillend zijn per toepassing. Indien de klant een voorkeur heeft, zal hier rekening mee gehouden worden. Maar als ik het niet eens ben en een beter voorstel heb, zal ik altijd mijn uiterste best doen om de klant te overtuigen.<br />" +
        "<br />" +
        "Hierna is het maar een kwestie van te programmeren en het uitwerken van het project."
}, {
    title: "Testing/Opvolging",
    description: "Bij grotere ontwikkelingen, geef ik mijn klanten graag toegang tot een test-applicatie zodat ze kunnen volgen hoe alles werkt. <br />" +
        "Tijdens de ontwikkelingsfase is het van belang om af en toe eens te polsen:<br />" +
        "<ul>" +
        "<li>Is alles naar wens</li>" +
        "<li>Zitten we nog op dezelfde golflengte</li>" +
        "<li>Zijn er vragen of opmerkingen</li>" +
        "<li>Hoe ver staan we, wat is er veranderd, ...</li>" +

        "</ul>" +
        "Dit kan door een vergadering, maar het kan ook zo simpel zijn als een E-post of telefonisch bericht."
}, {
    title: "Oplevering",
    description: "Wanneer de ontwikkeling afgerond is en de klant tevreden is met het resultaat moeten er meestal nog een aantal dingen gebeuren, zoals:<br />" +
        "<ul>" +
        "<li>De applicatie online zetten (hosten/deployen)</li>" +
        "<li>Een domeinnaam toevoegen</li>" +
        "<li>Nog een afwerking hier en daar</li>" +
        "</ul>" +
        "Als dit gedaan is wordt er gefactureerd en de code wordt afgeleverd."
}, {
    title: "Nazorg",
    description: "Het is natuurlijk vanzelfsprekend dat er in de toekomst, als de applicatie volop gebruikt wordt, er aanpassingen en/of toevoegingen nodig zijn. Ik engageer mij dus ook om hier ondersteuning te geven voor een x-aantal maanden na verkoop, mét hetzelfde uurtarief."
}];

function WorkFlow() {
    document.title = "LDIT - Manier van werken";

    return (
        <div className={"bg-light"}>
            <div className={"bg-white "} style={{clipPath: "polygon(0% 0%, 0% 70%, 50% 100%, 100% 70%, 100% 0%)"}}>
                <div className={"container"} style={{paddingBottom: '20vh', paddingTop: '10vh'}}>
                    <div className="d-flex  flex-md-nowrap flex-wrap gap-5 justify-content-center">
                        <div className={"my-4 d-flex justify-content-center"}>
                            <svg xmlns="http://www.w3.org/2000/svg" height={150} viewBox="0.01 64 224.02 384">
                                <path fill="rgba(230,25,51,1)" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                            </svg>
                        </div>
                        <div className="d-flex justify-content-center flex-column">
                            <div>
                                <h1>Manier van werken</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-5 bg-light d-flex flex-column gap-3">
                {items.map((value, index) => {
                    return <Box id={index} title={value.title} description={value.description} key={index} />;
                })}
            </div>
        </div>
    );
}

export default WorkFlow;