import React from "react";
import logo from "../ldit-red.png";
import {NavLink} from "react-router-dom";

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light p-5">
            <div className="container">
                <a className="navbar-brand" href="#">
                    <svg width={130} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{enableBackground:'new" 0="" 250="" 150'}} xmlSpace="preserve" viewBox="7.5 32.5 235 85">                         <g> 	                        <path fill="#E42332" d="M7.5,117.5v-84h17.6v68.7h41.6v15.3H7.5z"></path>                             <path fill="#E42332" d="M74.2,117.5v-84h32.5c5.6,0,10.9,0.9,15.7,2.8c4.8,1.9,8.9,4.6,12.3,8.2c3.4,3.5,6.1,7.8,8,12.9 		c1.9,5.1,2.9,10.8,2.9,17.2c0,6.4-0.9,12.2-2.6,17.5c-1.7,5.3-4.2,9.8-7.4,13.5c-3.2,3.8-7,6.7-11.5,8.7c-4.5,2.1-9.4,3.1-14.8,3.1 		H74.2z M105.8,103.2c7.7,0,13.2-2.4,16.7-7.1c3.5-4.8,5.2-11.9,5.2-21.4c0-4.7-0.4-8.8-1.3-12.1c-0.8-3.4-2.2-6.1-4.1-8.3 		c-1.9-2.1-4.3-3.7-7.3-4.7c-3-1-6.5-1.5-10.7-1.5h-13v55.1H105.8z"></path>                             <g><rect x="153.1" y="55.3" fill="#E42332" width="16.4" height="62.2"></rect>                                 <polygon fill="#E42332" points="174.2,32.5 153.1,32.5 153.1,47.8 174.2,47.8 199.5,47.8 199.5,117.5 217,117.5 217,47.8 242.5,48.1 			242.5,32.5 		"></polygon>                     </g>                 </g>                 </svg>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto  mb-2 mb-lg-0 align-items-center fs-4">
                        <li className="nav-item me-2">
                            <NavLink className="nav-link" activeClassName={"nav-link active"} to="/home">Home</NavLink>
                        </li>
                        <li className="nav-item me-2">
                            <NavLink className="nav-link" activeClassName={"nav-link active"} to="/workFlow">Manier van werken</NavLink>
                        </li>
                        <li className="nav-item me-2">
                            <NavLink className="nav-link" activeClassName={"nav-link active"} to="/portFolio">Portfolio</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName={"nav-link active"} to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;