import React from "react";


/*  <div className={"bg-white p-3 mx-5 border shadow-sm"}>

        </div>*/

function Box({title, description, image, id}) {
    return (
        <div className={"col-md-6 col-sm-12 p-2"}>
            <div className="bg-white rounded border shadow-sm d-flex flex-column h-100">
                <div className={"p-3 align-items-center justify-content-center d-flex mb-auto align-items-center h-100"}>
                    <img src={image} className="" alt="..." width={'100%'}/>
                </div>
                <div className="p-2 border-top text-center">
                    <h5 className="m-0">{title}</h5>
                    <p dangerouslySetInnerHTML={{__html: description}}/>
                </div>
            </div>
        </div>

    );
}

export default Box;