import React, {useEffect, useState} from "react";
import Box from "./Box";

function PortFolio() {
    document.title = "LDIT - Portfolio";

    const [items, setItems] = useState([]);

    useEffect(() => {
        (async () => {
            setItems( [{
                title: "Datamundi",
                description: "Datamundi, nu overgenomen door Summa Linguae Technologies, is gespecialiseerd in het analyseren, labelen, taggen en aanpassen van linguïstische data. <br />" +
                    "De klanten van Datamundi gebruiken NLP om vertaalmachines, searchengines of speechbots te maken. <br />" +
                    "<br />" +
                    "Wat heb ik gedaan voor Datamundi?<br />" +
                    "Een groot portaal (onderverdeeld in subportalen) waar de project managers, de freelancers en klanten verschillende rollen hebben. Zo kan de klant zijn data opsturen en afhalen, de project manager een job maken, opvolgen en post-processen, de freelancer de job nemen, eraan werken en factureren, de boekhouders facturen betalen en geldstromen analyseren. Alles zit heel soepel ineen. Zo hebben de project managers een heel breed platform waar ze per type job heel wat  specifieke info te zien kregen (denk dan aan betrouwbaarheid van de data, QC/QA, ...). <br />" +
                    "Het is een platform dat nooit stilstaat en alsmaar verandert!<br />" +
                    "<br />" +
                    "Buiten deze hele korte samenvatting zijn er uiteraard ook allerlei andere projecten die ik voor Datamundi heb gedaan en hier los van staan. Machinevertaling applicaties, web scrapers, TSV editor (online excel zonder formules), scripts, ….",
                image: (await import('../../../images/cropped-Logo_blue-green-1-220x27.png')).default
            }, {
                title: "FESG",
                description: "FESG biedt oplossingen aan i.v.m. brandveiligheid, regels en wetten hier rond. Ook geven ze advies en passen ze toe.<br />" +
                    "<br />" +
                    "Wat heb ik gedaan voor FESG?<br />" +
                    "Een tool waar ze alle sites, gebouwen en onderdelen van brandveiligheid kunnen zien met daaraan gekoppelde grafieken. Hier kunnen ze opvolgingslijsten invullen, opmerkingen aan toevoegen en analyseren. Deze applicatie bevat ook meerdere rollen, iemand die de sites, gebouwen en vraaglijsten opsteld en iemand die deze invult.<br />" +
                    "\t<br />" +
                    "Een tool waar een personeelslid meerdere vragen (onderverdeeld in subcategorieën) invult om uiteindelijk een document, calculaties en samenvattingen te verkrijgen. Deze formulieren kunnen ook gedeeld worden met andere werknemers. Python scripts kunnen online worden geëxecuteerd op deze formulieren.",
                image: (await import('../../../images/logo-fesg (1).png')).default
            }, {
                title: "DNA-safe",
                description: "DNA-safe is een startup die veel waarde hecht aan de privacy van DNA data. Zo kunnen modellen gebruikt worden op DNA data zonder dat de modellen iets afweten van de DNA data.<br />" +
                    "<br />" +
                    "Wat heb ik gedaan voor DNA-safe:<br />" +
                    "De gebruiker genereert encryptie sleutels via de web applicatie. DNA bestanden zoals die van 23andME worden geüpload met deze sleutel, hierbij worden web workers gebruikt omdat dit een zeer intensieve taak is. Het bestand wordt volledig homomorf versluiteld en opgeslagen.<br />" +
                    "Hierna kan de gebruiker verschillende medicijnen en ziektes testen (via modellen) op zijn DNA data. Dit wordt gedaan via message queues omdat dit opnieuw zeer intensieve taken zijn. Op het moment dat het resultaat beschikbaar is, decrypteert de gebruiker het resultaat en ziet hij diverse info over het medicijn/ziekte en de daarbij gerelateerde DNA data van de persoon. Hoe zou ik reageren op een medicament, is deze ziekte erfelijk (voor mijn DNA data), …",
                image: (await import('../../../images/logo.png')).default
            }]);
        })();
    }, []);

    return (
        <div className={"bg-light"}>
            <div className={"bg-white "} style={{clipPath: "polygon(0% 0%, 0% 70%, 50% 100%, 100% 70%, 100% 0%)"}}>
                <div className={"container"} style={{paddingBottom: '20vh', paddingTop: '10vh'}}>
                    <div className="d-flex gap-4 flex-md-nowrap flex-wrap justify-content-center">
                        <div className={"my-4 d-flex justify-content-center"}>
                            <svg xmlns="http://www.w3.org/2000/svg" height={150} viewBox="32 63.97 224 384.02">
                                <path fill="rgba(230,25,51,1)" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" />
                            </svg>
                        </div>
                        <div className="d-flex justify-content-center flex-column">
                            <div>
                                <h1>Portfolio</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container pt-5 bg-light  row mx-auto" >
                {items.map((value, index) => {
                    return <Box id={index} title={value.title} image={value.image} description={value.description} key={index} />;
                })}
            </div>
        </div>
    );
}

export default PortFolio;
